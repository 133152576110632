export const AppPaths = {
  HOME_PAGE: "/",
  CREATE_STORE: "/create_store",
  SHIPPING_DELIVERY: "/shipping-delivery",
  QUERIES: "/queries",
  FAQS: "/faqs",
  SIGNUP: "/signup",
  PLANS: "/plans",
  SHIPPING_LOGISTICS: "/shipping_logistics",
  CART: "/cart",
  ACCOUNT: "/account",
  SAVED_PRODUCTS: "/saved_products",
  REVIEWS_RATINGS: "/reviews_ratings",
  SELLER_PROFILE: "/seller_profile",
  ACL_SIGNUP: "/acl_signup/:token",
  CONTACT_US: "/contact_us",
  ABOUT_US: "/about_us",
  POLICIES: "/policies",
  SELLER_TERMS_POLICIES: "/seller_terms_policies",
  TERMS: "/terms",
  SHOPPIO_FOR_SELLERS: "/shoppio_for_sellers",
  CANCELLATION_REFUND: "/cancellation-refund",
  VIEW_CATEGORIES: "/:storeid/view_categories",
  PRODUCT_ADD: "/product_add/:storeid",
  ADD_PRODUCT: "/:storeid/add_product",
  UPDATE_PRODUCT: "/:storeid/update_product/:product_id",
  PRODUCT_DETAILS: "/product/:storeid/:id",
  ADD_STORE_WITH_ID: "/add_store/:storeid",
  ADD_STORE: "/add_store",
  ADD_CATEGORY: "/:storeid/add_category",
  UPDATE_CATEGORY: "/:storeid/update_category/:category_id",
  ADD_SUBCATEGORY: "/:storeid/:category_id/add_subcategory",
  UPDATE_SUBCATEGORY:
    "/:storeid/:category_id/update_subcategory/:subcategory_id",
  PLACE_ORDER: "/place_order",
  SELECT_ADDRESS: "/select_address",
  SELECT_COUPONS: "/select_coupons",
  CUSTOMER_MESSAGES: "/:storeid/customer_messages",
  SELLER_MESSAGES: "/store/:id#seller_messages",
  MY_ADDRESSES: "/my_addresses",
  ORDERS: "/orders",
  SUB_CATEGORY: "/sub_category/:value",
  STORE_HOME: "/store/:id",
  ACL: "/acl/:storeid",
  DELIVERY: "/delivery/:storeid",
  COUPON_CODES: "/:storeid/coupon_codes",
  CATEGORY_HOME: "/category/:storeid/:category_id",
  SUBCATEGORY_HOME: "/subcategory/:storeid/:category_id/:subcategory_id",
  SUPERUSER_DASHBOARD: "/superuser",
  STORE_DASHBOARD: "/admin/store_dash/:storeid",
  MANAGE_STORE: "/admin/manage_store/:storeid",
  MANAGE_DELIVERIES: "/admin/manage_deliveries/:storeid",
  DELIVERIES: "/admin/deliveries/:storeid",
  STORE_MANAGEMENT_WITH_ID: "/store_management/:storeid",
  STORE_MANAGEMENT: "/store_management",
  SELLER_DASHBOARD: "/seller_dashboard",
  STORE_ANALYTICS: "/store_analytics",
  INTEGRATIONS: "/integrations",
  KYC_STATUS: "/kyc_status",
  REVIEW_STATUS: "/review_status",
  NATIVE_APPS: "/native_apps",
  LOGIN: "/login",
  LOGOUT: "/logout",
  CUSTOMERS_PAGE: "/customers_page",
  PRICING_BILLING: "/pricing_billing",
  MARKETING: "/marketing",
  QUERY_RESOLUTION: "/query_resolution",
  CUSTOMER_CHAT: "/customer_chat",
  STORE_THEME: "/theme/:store_id",
  THEME: "/theme",
  REVIEW_RATING: "/review_rating",
  JOIN: "/join",
  SALES_DASHBOARD: "/sales_dashboard",
  ENGINEERING_DASHBOARD: "/engineering_dashboard",
  SELLER_SIGNUP: "/seller/sign_up",
  ACCOUNT_SETUP: "/account_setup",
  UPDATE_ACCOUNT: "/update_account",
  SHOPS: "/shops",
  CAREER: "/career",
  LANDING_PAGE: "/",
  STORE_LINK: "/:store_link",
  USER_STORES: "/user_stores",
}

export const getPath = (path, queries = {}) => {
  let pathTemplate = path

  Object.keys(queries).forEach(key => {
    if (pathTemplate.includes(`:${key}`)) {
      pathTemplate = pathTemplate.replace(`:${key}`, queries[key])
    }
  })

  console.log(">>>>>>>>>>", pathTemplate)

  return pathTemplate
}
