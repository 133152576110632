import React, { useEffect } from "react"
import { Portal } from "uiComponents"
import ReactDOM from "react-dom"
import "./dialog.css"

export const DialogHeader = ({ children }) => {
  return <div className='dialog-header'>{children}</div>
}
export const DialogAction = ({ children }) => {
  return <div className='dialog-action'>{children}</div>
}
const Dialog = props => {
  const {
    open,
    onClose,
    big,
    right,
    closeOnOverlay,
    header,
    actions,
    noScroll,
    popUp,
  } = props
  const getClass = open => {
    let classNames = "dialog zoom-in"
    if (open) classNames += " open"
    if (right) classNames += " right"
    if (big) classNames += " big"
    if (popUp) classNames += " pop-up"
    return classNames
  }
  useEffect(() => {
    if (open) document.body.style.overflowY = "hidden"
    else document.body.style.overflowY = "auto"
    return () => {
      document.body.style.overflowY = "auto"
    }
  }, [open])
  // console.log("actions", actions)
  return (
    <Portal simple open={open}>
      <div className={getClass(open)}>
        <div
          className='dialog-underlay'
          onClick={closeOnOverlay ? onClose : null}
        />
        {open && (
          <div
            className='dialog-content appear'
            style={{
              paddingTop: header ? 0 : "",
              overflowY: noScroll ? "hidden" : "",
            }}
            id={props.id}
          >
            {header ? <DialogHeader>{header}</DialogHeader> : ""}
            {actions ? <DialogAction>{actions}</DialogAction> : ""}

            <div>{props.children}</div>
            {onClose &&
              window.innerWidth < 700 &&
              ReactDOM.createPortal(
                <button
                  type='button'
                  onClick={onClose}
                  className='dialog-close'
                >
                  <i className='lni lni-xmark' />
                </button>,
                document.body,
              )}
            {onClose && window.innerWidth >= 700 && (
              <button type='button' className='dialog-close' onClick={onClose}>
                <i className='lni lni-xmark' />
              </button>
            )}
          </div>
        )}
      </div>
    </Portal>
  )
}

export default Dialog
