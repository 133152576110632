export const countriesWithFlags = [
  { name: "Afghanistan", code: "AF", flag: "🇦🇫" },
  { name: "Albania", code: "AL", flag: "🇦🇱" },
  { name: "Algeria", code: "DZ", flag: "🇩🇿" },
  { name: "Andorra", code: "AD", flag: "🇦🇩" },
  { name: "Angola", code: "AO", flag: "🇦🇴" },
  { name: "Argentina", code: "AR", flag: "🇦🇷" },
  { name: "Armenia", code: "AM", flag: "🇦🇲" },
  { name: "Australia", code: "AU", flag: "🇦🇺" },
  { name: "Austria", code: "AT", flag: "🇦🇹" },
  { name: "Azerbaijan", code: "AZ", flag: "🇦🇿" },
  { name: "Bahamas", code: "BS", flag: "🇧🇸" },
  { name: "Bahrain", code: "BH", flag: "🇧🇭" },
  { name: "Bangladesh", code: "BD", flag: "🇧🇩" },
  { name: "Barbados", code: "BB", flag: "🇧🇧" },
  { name: "Belarus", code: "BY", flag: "🇧🇾" },
  { name: "Belgium", code: "BE", flag: "🇧🇪" },
  { name: "Belize", code: "BZ", flag: "🇧🇿" },
  { name: "Benin", code: "BJ", flag: "🇧🇯" },
  { name: "Bhutan", code: "BT", flag: "🇧🇹" },
  { name: "Bolivia", code: "BO", flag: "🇧🇴" },
  { name: "Bosnia and Herzegovina", code: "BA", flag: "🇧🇦" },
  { name: "Botswana", code: "BW", flag: "🇧🇼" },
  { name: "Brazil", code: "BR", flag: "🇧🇷" },
  { name: "Brunei", code: "BN", flag: "🇧🇳" },
  { name: "Bulgaria", code: "BG", flag: "🇧🇬" },
  { name: "Burkina Faso", code: "BF", flag: "🇧🇫" },
  { name: "Burundi", code: "BI", flag: "🇧🇮" },
  { name: "Cambodia", code: "KH", flag: "🇰🇭" },
  { name: "Cameroon", code: "CM", flag: "🇨🇲" },
  { name: "Canada", code: "CA", flag: "🇨🇦" },
  { name: "Cape Verde", code: "CV", flag: "🇨🇻" },
  { name: "Central African Republic", code: "CF", flag: "🇨🇫" },
  { name: "Chad", code: "TD", flag: "🇹🇩" },
  { name: "Chile", code: "CL", flag: "🇨🇱" },
  { name: "China", code: "CN", flag: "🇨🇳" },
  { name: "Colombia", code: "CO", flag: "🇨🇴" },
  { name: "Comoros", code: "KM", flag: "🇰🇲" },
  { name: "Congo (Congo-Brazzaville)", code: "CG", flag: "🇨🇬" },
  { name: "Congo (DRC)", code: "CD", flag: "🇨🇩" },
  { name: "Costa Rica", code: "CR", flag: "🇨🇷" },
  { name: "Croatia", code: "HR", flag: "🇭🇷" },
  { name: "Cuba", code: "CU", flag: "🇨🇺" },
  { name: "Cyprus", code: "CY", flag: "🇨🇾" },
  { name: "Czech Republic", code: "CZ", flag: "🇨🇿" },
  { name: "Denmark", code: "DK", flag: "🇩🇰" },
  { name: "Djibouti", code: "DJ", flag: "🇩🇯" },
  { name: "Dominica", code: "DM", flag: "🇩🇲" },
  { name: "Dominican Republic", code: "DO", flag: "🇩🇴" },
  { name: "Ecuador", code: "EC", flag: "🇪🇨" },
  { name: "Egypt", code: "EG", flag: "🇪🇬" },
  { name: "El Salvador", code: "SV", flag: "🇸🇻" },
  { name: "Equatorial Guinea", code: "GQ", flag: "🇬🇶" },
  { name: "Eritrea", code: "ER", flag: "🇪🇷" },
  { name: "Estonia", code: "EE", flag: "🇪🇪" },
  { name: "Eswatini", code: "SZ", flag: "🇸🇿" },
  { name: "Ethiopia", code: "ET", flag: "🇪🇹" },
  { name: "Fiji", code: "FJ", flag: "🇫🇯" },
  { name: "Finland", code: "FI", flag: "🇫🇮" },
  { name: "France", code: "FR", flag: "🇫🇷" },
  { name: "Gabon", code: "GA", flag: "🇬🇦" },
  { name: "Gambia", code: "GM", flag: "🇬🇲" },
  { name: "Georgia", code: "GE", flag: "🇬🇪" },
  { name: "Germany", code: "DE", flag: "🇩🇪" },
  { name: "Ghana", code: "GH", flag: "🇬🇭" },
  { name: "Greece", code: "GR", flag: "🇬🇷" },
  { name: "Grenada", code: "GD", flag: "🇬🇩" },
  { name: "Guatemala", code: "GT", flag: "🇬🇹" },
  { name: "Guinea", code: "GN", flag: "🇬🇳" },
  { name: "Guinea-Bissau", code: "GW", flag: "🇬🇼" },
  { name: "Guyana", code: "GY", flag: "🇬🇾" },
  { name: "Haiti", code: "HT", flag: "🇭🇹" },
  { name: "Honduras", code: "HN", flag: "🇭🇳" },
  { name: "Hungary", code: "HU", flag: "🇭🇺" },
  { name: "Iceland", code: "IS", flag: "🇮🇸" },
  { name: "India", code: "IN", flag: "🇮🇳" },
  { name: "Indonesia", code: "ID", flag: "🇮🇩" },
  { name: "Iran", code: "IR", flag: "🇮🇷" },
  { name: "Iraq", code: "IQ", flag: "🇮🇶" },
  { name: "Ireland", code: "IE", flag: "🇮🇪" },
  { name: "Israel", code: "IL", flag: "🇮🇱" },
  { name: "Italy", code: "IT", flag: "🇮🇹" },
  { name: "Jamaica", code: "JM", flag: "🇯🇲" },
  { name: "Japan", code: "JP", flag: "🇯🇵" },
  { name: "Jordan", code: "JO", flag: "🇯🇴" },
  { name: "Kazakhstan", code: "KZ", flag: "🇰🇿" },
  { name: "Kenya", code: "KE", flag: "🇰🇪" },
  { name: "Kiribati", code: "KI", flag: "🇰🇮" },
  { name: "Kuwait", code: "KW", flag: "🇰🇼" },
  { name: "Kyrgyzstan", code: "KG", flag: "🇰🇬" },
  { name: "Laos", code: "LA", flag: "🇱🇦" },
  { name: "Latvia", code: "LV", flag: "🇱🇻" },
  { name: "Lebanon", code: "LB", flag: "🇱🇧" },
  { name: "Lesotho", code: "LS", flag: "🇱🇸" },
  { name: "Liberia", code: "LR", flag: "🇱🇷" },
  { name: "Libya", code: "LY", flag: "🇱🇾" },
  { name: "Liechtenstein", code: "LI", flag: "🇱🇮" },
  { name: "Lithuania", code: "LT", flag: "🇱🇹" },
  { name: "Luxembourg", code: "LU", flag: "🇱🇺" },
  { name: "Madagascar", code: "MG", flag: "🇲🇬" },
  { name: "Malawi", code: "MW", flag: "🇲🇼" },
  { name: "Malaysia", code: "MY", flag: "🇲🇾" },
  { name: "Maldives", code: "MV", flag: "🇲🇻" },
  { name: "Mali", code: "ML", flag: "🇲🇱" },
  { name: "Malta", code: "MT", flag: "🇲🇹" },
  { name: "Marshall Islands", code: "MH", flag: "🇲🇭" },
  { name: "Mauritania", code: "MR", flag: "🇲🇷" },
  { name: "Mauritius", code: "MU", flag: "🇲🇺" },
  { name: "Mexico", code: "MX", flag: "🇲🇽" },
  { name: "Micronesia", code: "FM", flag: "🇫🇲" },
  { name: "Moldova", code: "MD", flag: "🇲🇩" },
  { name: "Monaco", code: "MC", flag: "🇲🇨" },
  { name: "Mongolia", code: "MN", flag: "🇲🇳" },
  { name: "Montenegro", code: "ME", flag: "🇲🇪" },
  { name: "Morocco", code: "MA", flag: "🇲🇦" },
  { name: "Mozambique", code: "MZ", flag: "🇲🇿" },
  { name: "Myanmar (Burma)", code: "MM", flag: "🇲🇲" },
  { name: "Namibia", code: "NA", flag: "🇳🇦" },
  { name: "Nauru", code: "NR", flag: "🇳🇷" },
  { name: "Nepal", code: "NP", flag: "🇳🇵" },
  { name: "Netherlands", code: "NL", flag: "🇳🇱" },
  { name: "New Zealand", code: "NZ", flag: "🇳🇿" },
  { name: "Nicaragua", code: "NI", flag: "🇳🇮" },
  { name: "Niger", code: "NE", flag: "🇳🇪" },
  { name: "Nigeria", code: "NG", flag: "🇳🇬" },
  { name: "North Korea", code: "KP", flag: "🇰🇵" },
  { name: "North Macedonia", code: "MK", flag: "🇲🇰" },
  { name: "Norway", code: "NO", flag: "🇳🇴" },
  { name: "Oman", code: "OM", flag: "🇴🇲" },
  { name: "Pakistan", code: "PK", flag: "🇵🇰" },
  { name: "Palau", code: "PW", flag: "🇵🇼" },
  { name: "Panama", code: "PA", flag: "🇵🇦" },
  { name: "Papua New Guinea", code: "PG", flag: "🇵🇬" },
  { name: "Paraguay", code: "PY", flag: "🇵🇾" },
  { name: "Peru", code: "PE", flag: "🇵🇪" },
  { name: "Philippines", code: "PH", flag: "🇵🇭" },
  { name: "Poland", code: "PL", flag: "🇵🇱" },
  { name: "Portugal", code: "PT", flag: "🇵🇹" },
  { name: "Qatar", code: "QA", flag: "🇶🇦" },
  { name: "Romania", code: "RO", flag: "🇷🇴" },
  { name: "Russia", code: "RU", flag: "🇷🇺" },
  { name: "Rwanda", code: "RW", flag: "🇷🇼" },
  { name: "Saint Kitts and Nevis", code: "KN", flag: "🇰🇳" },
  { name: "Saint Lucia", code: "LC", flag: "🇱🇨" },
  { name: "Saint Vincent and the Grenadines", code: "VC", flag: "🇻🇨" },
  { name: "Samoa", code: "WS", flag: "🇼🇸" },
  { name: "San Marino", code: "SM", flag: "🇸🇲" },
  { name: "Sao Tome and Principe", code: "ST", flag: "🇸🇹" },
  { name: "Saudi Arabia", code: "SA", flag: "🇸🇦" },
  { name: "Senegal", code: "SN", flag: "🇸🇳" },
  { name: "Serbia", code: "RS", flag: "🇷🇸" },
  { name: "Seychelles", code: "SC", flag: "🇸🇨" },
  { name: "Sierra Leone", code: "SL", flag: "🇸🇱" },
  { name: "Singapore", code: "SG", flag: "🇸🇬" },
  { name: "Slovakia", code: "SK", flag: "🇸🇰" },
  { name: "Slovenia", code: "SI", flag: "🇸🇮" },
  { name: "Solomon Islands", code: "SB", flag: "🇸🇧" },
  { name: "Somalia", code: "SO", flag: "🇸🇴" },
  { name: "South Africa", code: "ZA", flag: "🇿🇦" },
  { name: "South Korea", code: "KR", flag: "🇰🇷" },
  { name: "South Sudan", code: "SS", flag: "🇸🇸" },
  { name: "Spain", code: "ES", flag: "🇪🇸" },
  { name: "Sri Lanka", code: "LK", flag: "🇱🇰" },
  { name: "Sudan", code: "SD", flag: "🇸🇩" },
  { name: "Suriname", code: "SR", flag: "🇸🇷" },
  { name: "Sweden", code: "SE", flag: "🇸🇪" },
  { name: "Switzerland", code: "CH", flag: "🇨🇭" },
  { name: "Syria", code: "SY", flag: "🇸🇾" },
  { name: "Taiwan", code: "TW", flag: "🇹🇼" },
  { name: "Tajikistan", code: "TJ", flag: "🇹🇯" },
  { name: "Tanzania", code: "TZ", flag: "🇹🇿" },
  { name: "Thailand", code: "TH", flag: "🇹🇭" },
  { name: "Timor-Leste", code: "TL", flag: "🇹🇱" },
  { name: "Togo", code: "TG", flag: "🇹🇬" },
  { name: "Tonga", code: "TO", flag: "🇹🇴" },
  { name: "Trinidad and Tobago", code: "TT", flag: "🇹🇹" },
  { name: "Tunisia", code: "TN", flag: "🇹🇳" },
  { name: "Turkey", code: "TR", flag: "🇹🇷" },
  { name: "Turkmenistan", code: "TM", flag: "🇹🇲" },
  { name: "Tuvalu", code: "TV", flag: "🇹🇻" },
  { name: "Uganda", code: "UG", flag: "🇺🇬" },
  { name: "Ukraine", code: "UA", flag: "🇺🇦" },
  { name: "United Arab Emirates", code: "AE", flag: "🇦🇪" },
  { name: "United Kingdom", code: "GB", flag: "🇬🇧" },
  { name: "United States", code: "US", flag: "🇺🇸" },
  { name: "Uruguay", code: "UY", flag: "🇺🇾" },
  { name: "Uzbekistan", code: "UZ", flag: "🇺🇿" },
  { name: "Vanuatu", code: "VU", flag: "🇻🇺" },
  { name: "Vatican City", code: "VA", flag: "🇻🇦" },
  { name: "Venezuela", code: "VE", flag: "🇻🇪" },
  { name: "Vietnam", code: "VN", flag: "🇻🇳" },
  { name: "Yemen", code: "YE", flag: "🇾🇪" },
  { name: "Zambia", code: "ZM", flag: "🇿🇲" },
  { name: "Zimbabwe", code: "ZW", flag: "🇿🇼" },
]

export const businessModels = [
  { value: "b2b", label: "Business to Business (B2B)" },
  { value: "b2c", label: "Business to Consumer (B2C)" },
  { value: "d2c", label: "Direct to Consumer (D2C)" },
  { value: "b2g", label: "Business to Government (B2G)" },
  { value: "b2b2c", label: "Business to Business to Consumer (B2B2C)" },
  { value: "c2c", label: "Consumer to Consumer (C2C)" },
  { value: "c2b", label: "Consumer to Business (C2B)" },
  { value: "g2c", label: "Government to Consumer (G2C)" },
  { value: "g2b", label: "Government to Business (G2B)" },
  { value: "b2e", label: "Business to Employee (B2E)" },
  { value: "e2e", label: "Enterprise to Enterprise (E2E)" },
  { value: "p2p", label: "Peer to Peer (P2P)" },
  { value: "m2m", label: "Machine to Machine (M2M)" },
  { value: "saaS", label: "Software as a Service (SaaS)" },
  { value: "paaS", label: "Platform as a Service (PaaS)" },
  { value: "iPaaS", label: "Integration Platform as a Service (iPaaS)" },
  { value: "dtaaS", label: "Data as a Service (DaaS)" },
  { value: "b2b2b", label: "Business to Business to Business (B2B2B)" },
  { value: "b2b2g", label: "Business to Business to Government (B2B2G)" },
]

export const serviceCategories = [
  { value: "appliance_repair", label: "Appliance Repair" },
  { value: "babysitting", label: "Babysitting" },
  { value: "bookkeeping", label: "Bookkeeping" },
  { value: "catering_services", label: "Catering Services" },
  { value: "car_repair", label: "Car Repair" },
  { value: "carpet_cleaning", label: "Carpet Cleaning" },
  { value: "content_writing", label: "Content Writing" },
  { value: "data_analysis", label: "Data Analysis" },
  { value: "digital_marketing", label: "Digital Marketing" },
  { value: "dog_walking", label: "Dog Walking" },
  { value: "e_commerce_solutions", label: "E-commerce Solutions" },
  { value: "electrician", label: "Electrician" },
  { value: "event_management", label: "Event Management" },
  { value: "event_planning", label: "Event Planning" },
  { value: "fashion_styling", label: "Fashion Styling" },
  { value: "financial_consulting", label: "Financial Consulting" },
  { value: "fitness_training", label: "Fitness Training" },
  { value: "gardening_services", label: "Gardening Services" },
  { value: "graphic_design", label: "Graphic Design" },
  { value: "health_coaching", label: "Health Coaching" },
  { value: "home_cleaning", label: "Home Cleaning" },
  { value: "home_renovation", label: "Home Renovation" },
  { value: "interior_design", label: "Interior Design" },
  { value: "language_translation", label: "Language Translation" },
  { value: "legal_advice", label: "Legal Advice" },
  { value: "mobile_app_development", label: "Mobile App Development" },
  { value: "music_lessons", label: "Music Lessons" },
  { value: "online_tutoring", label: "Online Tutoring" },
  { value: "photography", label: "Photography" },
  { value: "plumbing", label: "Plumbing" },
  { value: "pool_cleaning", label: "Pool Cleaning" },
  { value: "seo_consultation", label: "SEO Consultation" },
  { value: "social_media_marketing", label: "Social Media Marketing" },
  { value: "software_development", label: "Software Development" },
  { value: "travel_consultancy", label: "Travel Consultancy" },
  { value: "tattoo_artistry", label: "Tattoo Artistry" },
  { value: "virtual_assistance", label: "Virtual Assistance" },
  { value: "web_design", label: "Web Design" },
  { value: "wedding_planning", label: "Wedding Planning" },
]

export const productCategories = [
  { value: "electronics", label: "Electronics" },
  { value: "clothing", label: "Clothing" },
  { value: "home-furniture", label: "Home & Furniture" },
  { value: "books-media", label: "Books & Media" },
  { value: "health-beauty", label: "Health & Beauty" },
  { value: "jewelry-accessories", label: "Jewelry & Accessories" },
  { value: "toys-games", label: "Toys & Games" },
  { value: "food-grocery", label: "Food & Grocery" },
  { value: "handmade-crafts", label: "Handmade & Crafts" },
  { value: "automotive", label: "Automotive" },
  { value: "sports-outdoors", label: "Sports & Outdoors" },
  { value: "garden-outdoor", label: "Garden & Outdoor" },
  { value: "office-supplies", label: "Office Supplies" },
  { value: "electrical-appliances", label: "Electrical Appliances" },
  { value: "pet-supplies", label: "Pet Supplies" },
]

export const storeBusinessInfoFields = [
  {
    name: "name",
    label: "Store Name",
    type: "text",
    hint: "Please enter store name",
    required: true,
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "phoneNumber",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    hint: "Please enter a valid email address",
    required: true,
  },
  {
    name: "website",
    label: "Website",
    type: "text",
    hint: "Please enter the URL of your store website",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "textarea",
    hint: "Please provide a brief description of your store",
    fullWidth: true,
  },
  {
    name: "logo",
    label: "Logo",
    type: "pic",
    hint: "Please upload a logo for your store",
    required: true,
  },
  {
    name: "banner",
    label: "Store Banner",
    type: "pic",
  },
]

export const storeTypeFields = (getCategoryOptions, setStoreType) => [
  {
    name: "store_type",
    label: "Store Type",
    type: "select",
    children: [
      { value: "product", label: "Products" },
      { value: "service", label: "Services" },
      { value: "both", label: "Both" },
    ].map(op => (
      <option key={op.value} value={op.value}>
        {op.label}
      </option>
    )),
    required: true,
    onChange: e => setStoreType(e.target.value),
  },
  {
    name: "store_category",
    label: "Store Category",
    type: "select",
    children: getCategoryOptions()?.map(category => (
      <option key={category.value} value={category.label}>
        {category.label}
      </option>
    )),
    required: true,
  },
  {
    name: "business_model",
    label: "Business Model",
    type: "select",
    children: businessModels?.map(model => (
      <option key={model.value} value={model.label}>
        {model.label}
      </option>
    )),
    required: true,
  },
  {
    name: "payment_method",
    label: "Payment Method",
    type: "radio",
    dataTip: "Please choose your payment method",
    inline: true,
    options: ["Cash", "Online", "Both"].map(v => ({ label: v, value: v })),
  },
]

export const storeLocationFields = () => [
  {
    name: "street",
    label: "Street Address",
    type: "text",
    hint: "Please enter street address",
    required: true,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    hint: "Please enter city",
    required: true,
  },
  {
    name: "state",
    label: "State/Province",
    type: "text",
    hint: "Please enter state",
    required: true,
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    children: countriesWithFlags?.map(country => (
      <option key={country.code} value={country.name}>
        {country.name}
      </option>
    )),
    required: true,
  },
  {
    name: "location",
    label: "Location",
    type: "location",
    required: true,
  },
]

export const processes = {
  GET_STORE_DATA: { id: "getStoreData" },
  GET_PRICING_MODELS: { id: "getPricingModel" },
}

export const statusStyles = {
  Draft: {
    color: "#808080",
    icon: "edit",
  },
  Scheduled: {
    color: "#d1b72f",
    icon: "calendar",
  },
  Live: {
    color: "#32CD32",
    icon: "play",
  },
  Completed: {
    color: "#4CAF50",
    icon: "check",
  },
  Pending: {
    color: "#FFA500",
    icon: "exclamation",
  },
}
